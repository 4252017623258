import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import Utils from '@shared/helpers/utils';
import {
  CUnidadFormativa,
  IAlcance,
  ICampus,
  IEscuela,
  IPeriodo,
  IPeriodoSemanal,
  IRegion,
} from '@shared/Interface/ICatalogos.interface';
import { IRetoMaestro } from '@shared/Interface/IReto.interface';
import { ISocioFormadorResponse } from '@shared/Interface/ISocioFormadorResponse';
import { IUser } from '@shared/Interface/user';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { RetosService } from '@shared/service/Retos/retos.service';
import { SolicitudesService } from '@shared/service/Solicitudes/solicitudes.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-academico-solicitud-sf-form',
  templateUrl: './academico-solicitud-sf-form.component.html',
  styleUrls: ['./academico-solicitud-sf-form.component.css'],
})
export class AcademicoSolicitudSfFormComponent implements OnInit {
  @Input()
  contextoOrigen: string = '';
  @Input()
  socioFormadorDetalle: ISocioFormadorResponse;
  @Input()
  MatchObjSocio: any;
  @Input()
  idReto: number = 0;
  @Input()
  idSocio: number = 0;
  @Input()
  idLogBusquedaSocio: number = 0;
  @Input()
  showDescriptionReto: boolean = true;
  @Input()
  idTipoSolicitud: number = 5;
  @Input()
  buttonText: string = 'Quiero Vincularlo';
  @Input()
  textToAcademicUser: string =
    'NOTA: Esta solicitud de vinculación llegará al CVDP quien te apoyará a encontrar al Socio Formador ideal para tu reto, tomando como referencia la descripción de la Unidad Formativa.';
  @Input()
  showToggleButton: boolean = true;
  @Input()
  showOnlyForm: boolean = false;
  @Input()
  askForPartnerDetails: boolean = false;

  @ViewChild('ddCampusDataRegion') ddCampusDataRegion: NgSelectComponent;

  constructor(
    private usuarioService: UsuariosService,
    private retoService: RetosService,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private catalogosService: CatalogosService,
    private solicitudesService: SolicitudesService
  ) {
    this.userStore = usuarioService.getCurrentUserData();
  }

  async ngOnInit() {
    // console.log('init', {
    //   contextoOrigen: this.contextoOrigen,
    //   origenVinculacion: localStorage.getItem('origenVinculacion'),
    //   socioFormadorDetalle: this.socioFormadorDetalle,
    //   MatchObjSocio: this.MatchObjSocio,
    //   idReto: this.idReto,
    //   idSocio: this.idSocio,
    //   idLogBusquedaSocio: this.idLogBusquedaSocio,
    // });
    await this.getEscuela(this.userStore.idEscuela);
    this.initializeForm();

    if (!this.showToggleButton) {
      this.mostarFormularioVinculacion();
    }

    try {
      this.regionData = await lastValueFrom(
        this.catalogosService.getRegiones()
      );
    } catch (err) {
      Utils.processErrorResponse(err);
    }

    if (['bancoRetos', 'matchReto', 'match'].includes(this.contextoOrigen)) {
      this.tieneReto = true;
      this.vinculacionForm.disable();
    } else {
      this.tieneReto = false;
      this.vinculacionForm.enable();
    }
    this.getReto();

    if (
      ['cvdp', 'cvdp_admin', 'academia'].includes(this.userStore.clavePerfil)
    ) {
      try {
        this.periodoAcademicoData = await lastValueFrom(
          this.catalogosService.getPeriodo(true, 0)
        );
      } catch (error) {
        Utils.processErrorResponse(error);
      }
      try {
        this.periodoSemanalData = await lastValueFrom(
          this.catalogosService.getPeriodoSemanal()
        );
      } catch (error) {
        Utils.processErrorResponse(error);
      }
      try {
        this.listAlcance = await lastValueFrom(
          this.catalogosService.getAlcance('sol-acad')
        );
      } catch (error) {
        Utils.processErrorResponse(error);
      }
      try {
        // Cargar campus donde llevar el reto
        let resultado: ICampus[] = await lastValueFrom(
          //this.catalogosService.getCampusxReto(this.idReto)
          this.catalogosService.getCampus()
        );

        // 20240718: solicitado por lulú: Zacatecas, Obregón, Irapuato, Chiapas, CD Juarez,  Nacional
        let campusExcluidos = [52, 31, 36, 27, 30, 53];
        // Se quitan los campos solicitados
        let campusFiltrados = resultado.filter(
          (campus) => !campusExcluidos.includes(campus.idCampus)
        );
        this.campusData = campusFiltrados;

        // Se realiza ordenamiento alfabetico en dos columnas solicitado
        if (this.campusData) {
          let halfItems = Math.round(this.campusData.length / 2);
          this.seccion1 = this.campusData.slice(0, halfItems);
          this.seccion2 = this.campusData.slice(halfItems);
        }
      } catch (error) {
        Utils.processErrorResponse(error);
      }

      this.getEscuela(0);
    }
    if ('academia' === this.userStore.clavePerfil) {
      this.getAcademicos();
    }
  }

  // Tipos de solicitud de academicos
  ACADEMICOBUSCANDOSFSINSOCIO = 3;
  ACADEMICOBUSCANDOSF = 5;

  // Vinculacion
  vinculacionForm: FormGroup;
  mostrarRequeridos: boolean = false;

  mostrarSeccionOtroProfesor: boolean = false;
  otroAcademicoSeleccionado;
  academicosData;
  mostrarDivSolVinculacion: boolean = false;

  pedirPeriodo: boolean = false;
  pedirPeriodoSemanal: boolean = false;
  pedirModalidad: boolean = false;
  periodoAcademicoData: IPeriodo[];
  periodoSemanalData: IPeriodoSemanal[];
  periodoSemanalChk: number[] = [];
  tieneReto: boolean = false;
  retoInfo: any;
  lbl_idiomas = '';
  lbl_semestre = '';
  lbl_periodoSemanal = '';
  lbl_periodoSemestral = '';
  imgQuieroVincularlo: string = 'assets/img/icon_abajo.svg';
  listModalidad: any[] = [
    { idModalidad: 1, modalidad: 'Presencial' },
    { idModalidad: 2, modalidad: 'Virtual' },
    { idModalidad: 3, modalidad: 'Híbrido' },
  ];
  modalidad: number = 0;
  periodoAcademicoSeleccionado: number = 0;
  ModalidadSeleccionada: number = 0;

  escuelaData: any;
  PERIDO_SEMANAL_SEMESTRAL = 4;
  unidadFormativaData: CUnidadFormativa[];
  mostrarFormulario: boolean = false;
  userStore: IUser;

  listAlcance: IAlcance[];

  ALCANCEREGION = 6;
  ALCANCECAMPUS = 5;

  pedirAlcance: boolean = false;
  alcanceSeleccionado: number = 0;

  //TODO quitar
  //idLogBusquedaSocio: number = 0;
  //idSocio: number;
  //idReto: number;

  //CampusSeleccionado: number;
  pedirListaCampus = false;
  //pedirCampusCoordinador = false;
  campusData: ICampus[];
  seccion1: ICampus[];
  seccion2: ICampus[];

  selectedCampus: number[] = [];
  listaCampusAdicional: FormControl = new FormControl();

  loadingRetoMaestro = false;

  regionData: IRegion[];
  campusDataRegion;
  idRegion: FormControl = new FormControl();

  caracteristicas_sf: FormControl = new FormControl();
  pedirRegion: boolean = false;
  pedirCampusServicio: boolean = false;
  pedirCaracteristicas: boolean = false;
  MostrarSolicitudVinculacionAcademico() {
    if (this.userStore.clavePerfil == 'academia') {
      //if (this.contextoOrigen == 'banco') {
      return true;
      //}
    }
    return false;
  }

  changeAcademicoEnNombreDeOtro(event) {
    this.mostrarSeccionOtroProfesor = !this.mostrarSeccionOtroProfesor;
    if (this.mostrarSeccionOtroProfesor) {
      this.otroAcademicoSeleccionado = null;
    }
  }

  seleccionarOtroAcademico(event) {
    this.otroAcademicoSeleccionado = event;
  }

  async getAcademicos() {
    try {
      let idUsuario = this.userStore.idUsuario;
      this.academicosData = await lastValueFrom(
        this.usuarioService.getAcademicosxNivelGestion(idUsuario)
      );
    } catch (error) {
      Utils.processErrorResponse(error);
    }
  }

  mostarFormularioVinculacion() {
    this.mostrarDivSolVinculacion = !this.mostrarDivSolVinculacion;
    this.mostrarFormulario = !this.mostrarFormulario;
    this.imgQuieroVincularlo = this.mostrarFormulario
      ? 'assets/img/icon_arriba.svg'
      : 'assets/img/icon_abajo.svg';

    if (['academia'].includes(this.userStore.clavePerfil)) {
      this.vinculacionForm
        .get('idEscuela')
        ?.patchValue(this.userStore.idEscuela);
      this.getUnidadFormativa(this.userStore.idEscuela);
    }
  }

  async getReto() {
    try {
      if (this.idReto != 0) {
        let reto = await lastValueFrom(
          this.retoService.getRetoMaestroDetail(this.idReto)
        );
        if (reto) {
          this.retoInfo = reto;
        }
      }
    } catch (error) {
      console.error('Error al obtener el detalle del reto por su ID', error);
    }
  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = Number(event.idPeriodo);
  }

  seleccionarAlcance(event: any) {
    this.listaCampusAdicional.reset;
    this.idRegion.reset;
    this.selectedCampus = [];
    this.alcanceSeleccionado = Number(event.idAlcance);
  }

  seleccionarModalidad(event: any) {
    this.ModalidadSeleccionada = Number(event.idModalidad);
  }

  verificaChecked(idPeriodoSemanal) {
    return this.periodoSemanalChk.includes(idPeriodoSemanal);
  }

  verificaCheckPeriodoSemanal(event) {
    let idPeriodoSemanal: number = Number(event.target.value);
    let index = this.periodoSemanalChk.findIndex((x) => x === idPeriodoSemanal);
    if (
      idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL &&
      event.target.checked
    ) {
      this.periodoSemanalChk = [1, 2, 3, 4];
    } else {
      if (idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL)
        this.periodoSemanalChk = [];
      else if (
        this.periodoSemanalChk.findIndex(
          (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
        ) > 0
      ) {
        this.periodoSemanalChk.splice(
          this.periodoSemanalChk.findIndex(
            (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
          ),
          1
        );
      }

      if (index < 0) {
        this.periodoSemanalChk.push(idPeriodoSemanal);
      } else {
        this.periodoSemanalChk.splice(index, 1);
      }
    }
  }

  getUnidadFormativa(event) {
    let idEscuela = event;

    this.catalogosService.getUnidadFormativa(idEscuela).subscribe({
      next: (resultado: CUnidadFormativa[]) => {
        this.unidadFormativaData = resultado;
      },
      error: (error) => {
        this.unidadFormativaData = [];
        this.tieneReto = false;
        this.retoInfo = null;
        this.vinculacionForm.get('idUnidadFormativa')?.patchValue({
          idUnidadFormativa: 0,
          clave: '',
          unidadFormativa: '',
        });

        Swal.fire({
          icon: 'info',
          text: 'No hay Unidades Formativas asociados',
        });
      },
    });
  }

  guardarLog(data) {
    if (this.MatchObjSocio?.matchScore) {
      this.retoService
        .postLogSocioElegido({
          idLogBusquedaSocio: this.idLogBusquedaSocio,
          matchScore: this.MatchObjSocio.matchScore,
          idSocioFormador: this.idSocio,
        })
        .subscribe();
    }

    /*if (this.idLogBusquedaSocio > 0) {
      this.solicitudesService
        .putSolicitud({
          idSolicitud: data.idSolicitud,
          idLogBusquedaSocio: this.idLogBusquedaSocio,
        })
        .subscribe();
      sessionStorage.removeItem('idLogBusquedaSocio');   
    }*/
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.vinculacionForm.get(inputForm)?.value === id ? true : null;
  }

  getRetoDetail(event: any) {
    const idUnidadFormativa = event;
    this.tieneReto = false;
    this.loadingRetoMaestro = true;
    this.retoService
      .getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa)
      .pipe(
        tap((res: IRetoMaestro[]) => {
          let resultado = res[0];

          this.retoService
            .getRetoMaestroDetail(resultado.idRetoMaestro)
            .subscribe({
              next: (resOK) => {
                this.lbl_idiomas = resOK.idiomas
                  .map((x) => x.idioma)
                  .join(', ');
                this.lbl_semestre = resOK.semestres
                  .map((x) => x.semestre)
                  .join(', ');
                this.lbl_periodoSemanal = resOK.periodosSemanales
                  .map((x) => x.periodoSemanal)
                  .join(', ');
                this.lbl_periodoSemestral = resOK.periodosSemestrales
                  .map((x) => x.periodoSemestral)
                  .join(', ');

                //this.tieneReto = true;
                this.retoInfo = resOK;
                this.loadingRetoMaestro = false;
              },
              error: (err) => {
                this.loadingRetoMaestro = false;
                Utils.processErrorResponse(err);
              },
            });
        })
      )
      .subscribe();
  }

  getEscuela(idEscuela: number) {
    this.catalogosService
      .getEscuelas(idEscuela)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
        })
      )
      .subscribe();
  }

  initializeForm() {
    this.vinculacionForm = this.fb.group({
      idEscuela: ['', Validators.required],
      idUnidadFormativa: ['', Validators.required],
    });
  }

  sendingStatus: Boolean = false;
  async quieroVincularmeaReto() {
    if(this.sendingStatus){
      console.log('trabajando...');
      return;
    }
    this.sendingStatus = true;

    this.mostrarRequeridos = true;
    this.pedirPeriodo = false;
    this.pedirPeriodoSemanal = false;
    this.pedirModalidad = false;
    this.pedirAlcance = false;
    this.pedirRegion = false;
    this.pedirCampusServicio = false;
    this.pedirListaCampus = false;
    this.pedirCaracteristicas = false;

    // Se tiene el arreglo del periodo semanal
    if (!this.periodoAcademicoSeleccionado) this.pedirPeriodo = true;
    if (this.periodoSemanalChk.length == 0) this.pedirPeriodoSemanal = true;
    if (this.ModalidadSeleccionada == 0) this.pedirModalidad = true;
    if (this.alcanceSeleccionado == 0) this.pedirAlcance = true;
    if (this.idRegion.value == null) this.pedirRegion = true;
    if (this.caracteristicas_sf.value == null) this.pedirCaracteristicas = true;

    // set campus values
    if (this.alcanceSeleccionado == this.ALCANCECAMPUS) {
      if (this.selectedCampus.length == 0) this.pedirListaCampus = true;
    }
    if (this.alcanceSeleccionado == this.ALCANCEREGION) {
      if (this.listaCampusAdicional.value == null) {
        this.pedirCampusServicio = true;
      }
      this.selectedCampus = this.listaCampusAdicional.value;
    }

    let idUsuarioAcademico = this.userStore.idUsuario;

    if (this.mostrarSeccionOtroProfesor && this.otroAcademicoSeleccionado) {
      idUsuarioAcademico = this.otroAcademicoSeleccionado.idUsuario;
    }

    if (this.mostrarSeccionOtroProfesor && !this.otroAcademicoSeleccionado) {
      this.toastr.error('Seleccionar el otro Académico');
      this.sendingStatus = false;
      return;
    }
    if (this.pedirPeriodo || this.pedirPeriodoSemanal || this.pedirModalidad) {
      this.toastr.error('Completa la información');
      this.sendingStatus = false;
      return;
    }
    if (this.pedirAlcance) {
      this.toastr.error('Seleccionar el Alcance de la solicitud');
      this.sendingStatus = false;
      return;
    }
    if (this.pedirRegion && this.alcanceSeleccionado == this.ALCANCEREGION) {
      this.toastr.error('Selecciona la región');
      this.sendingStatus = false;
      return;
    }
    if (
      this.pedirListaCampus &&
      this.alcanceSeleccionado == this.ALCANCECAMPUS
    ) {
      this.toastr.error('Selecciona al menos 1 campus');
      this.sendingStatus = false;
      return;
    }
    if (
      this.pedirCampusServicio &&
      this.alcanceSeleccionado == this.ALCANCEREGION
    ) {
      this.toastr.error('Selecciona al menos 1 campus');
      this.sendingStatus = false;
      return;
    }
    if (this.pedirCaracteristicas && this.askForPartnerDetails) {
      this.toastr.error('Escribe las características del SF');
      this.sendingStatus = false;
      return;
    }

    this.vinculacionForm.markAllAsTouched();

    // 5 = Solicitud A. con reto desde algoritmo o desde banco de Socios Formadores
    // 3 = Solicitud A. de Vinculación sin Socio Formador desde Banco de Retos
    let idSocioFormador = this.idSocio;
    if (this.socioFormadorDetalle) {
      idSocioFormador = this.socioFormadorDetalle.idSocioFormador;
    }

    let descripcionSolicitud = '';
    if (this.retoInfo && this.socioFormadorDetalle) {
      descripcionSolicitud = `Solicitud de Vinculación de Reto ${this.retoInfo.reto} a Socio Formador ${this.socioFormadorDetalle.nombreSocioFormador}`;
    }

    //let confirmationPage = 'confirmacion-vinculacion';
    let confirmationPage = 'reto-confirmacion';
    let idLogBusquedaReto = sessionStorage.getItem('idLogBusquedaReto') ?? 0;

    if (this.askForPartnerDetails) {
      descripcionSolicitud = `Características: ${this.caracteristicas_sf.value}`;
      confirmationPage = 'reto-confirmacion'; //'dashboard';
    } else {
      if (this.idTipoSolicitud == this.ACADEMICOBUSCANDOSFSINSOCIO) {
        descripcionSolicitud =
          'Solicitud sin Socio Formador desde Banco de Retos';
        idSocioFormador = 0;
        confirmationPage = 'reto-confirmacion';
        idLogBusquedaReto = 0;
      }
    }
    if (
      this.vinculacionForm.valid ||
      this.vinculacionForm.status == 'DISABLED'
    ) {
      //

      // console.log('save', {
      //   idTipoSolicitud: this.idTipoSolicitud,
      //   descripcionSolicitud: descripcionSolicitud,
      //   idSocioFormador: idSocioFormador,
      //   idCVDP: 0,
      //   idAcademia: idUsuarioAcademico,
      //   idUsuario: this.userStore.idUsuario,
      //   idProblematica: 0,
      //   idRetoMaestro: this.retoInfo.idRetoMaestro, //* revisar this.idReto
      //   idCampus: this.userStore.idCampus,
      //   idPeriodo: this.periodoAcademicoSeleccionado,
      //   periodosSemanales: this.periodoSemanalChk,
      //   idModalidad: this.ModalidadSeleccionada,
      //   idLogBusquedaReto: Number(idLogBusquedaReto),
      //   campusSeleccionados: this.selectedCampus,
      //   idAlcance: this.alcanceSeleccionado,
      //   idRegion: this.idRegion.value,
      //   idLogBusquedaSocio: this.idLogBusquedaSocio,
      // });

      // await Utils.sleep(2000);
      // this.toastr.success('Solicitud enviada');
      // this.sendingStatus = false;
      // return;

      try {
        console.log('entrando...');
        this.sendingStatus = true;

        let data = await lastValueFrom(
          this.solicitudesService.postSolicitud({
            idTipoSolicitud: this.idTipoSolicitud,
            descripcionSolicitud: descripcionSolicitud,
            idSocioFormador: idSocioFormador,
            idCVDP: 0,
            idAcademia: idUsuarioAcademico,
            idUsuario: this.userStore.idUsuario,
            idProblematica: 0,
            idRetoMaestro: this.retoInfo.idRetoMaestro, //* revisar this.idReto
            idCampus: this.userStore.idCampus,
            idPeriodo: this.periodoAcademicoSeleccionado,
            periodosSemanales: this.periodoSemanalChk,
            idModalidad: this.ModalidadSeleccionada,
            idLogBusquedaReto: Number(idLogBusquedaReto),
            idLogBusquedaSocio: this.idLogBusquedaSocio,
            campusSeleccionados: this.selectedCampus,
            idAlcance: this.alcanceSeleccionado,
            idRegion: this.idRegion.value,
          })
        );
        this.guardarLog(data);
        localStorage.setItem('retoSelected', JSON.stringify(this.retoInfo));
        if (this.socioFormadorDetalle) {
          localStorage.setItem(
            'socioSelected',
            JSON.stringify(this.socioFormadorDetalle)
          );
        }
        sessionStorage.removeItem('idLogBusquedaSocio');
        localStorage.removeItem('contextoOrigen');
        localStorage.removeItem('ppc');

        this.router.navigateByUrl(confirmationPage);
      } catch (error) {
        this.sendingStatus = false;
        Utils.processErrorResponse(error);
      }
    }
  }

  onCheckboxChange(event: any, cosa: string) {
    if (event.target.checked) {
      this.selectedCampus.push(Number(event.target.value));
    } else {
      const index = this.selectedCampus.indexOf(Number(event.target.value));
      this.selectedCampus.splice(index, 1);
    }
  }

  showCampus(event) {
    this.ddCampusDataRegion.handleClearClick();
    this.campusDataRegion = this.campusData.filter(
      (x) => x.idRegion == event.idRegion
    );
  }
}
